/* Functions for filling batches. These are tparcels designed to be created for validating fillings, and assigning cask IDs to a filling, by BatchID */

/** @typedef {Object} FillingBatch
 *  @property {String} batchId - The batch ID of the filling batch.
 *  @property {?String[]} casks - An optional set of caskIds scanned against the batch, held locally only.
 */

var scanningFillingBatch = {
  batchId: '',
  indexInStorage: null
};

function getBatchesFromStorage() {
  "use strict";
  return localStorage.getObject("data/fillingBatches");
}

function setBatchesInStorage(batches) {
  "use strict";
  localStorage.setObject("data/fillingBatches", batches);
}

/**
 * @description Take a set of batches, and merge with those already in LocalStorage. Remove any not in the set.
 * @param {FillingBatch[]} batches
 */
function saveBatchesToStorage(batches) {
  "use strict";

  // Load any from the LS. IF none exist yet, this will be an empty array.
  /** @type {FillingBatch[]} savedBatches */
  var savedBatches = getBatchesFromStorage();

  // Clear anything from the device that was not received
  for (var i = savedBatches.length-1; i>=0 ; i--) {
    var stillDownload = searchListForItem(batches, 'batchId', savedBatches[i].batchId);
    if (stillDownload === null) {
      savedBatches.splice(i, 1);
    }
  }

  // Add new batchIds to the array.
  for (var j = 0; j < batches.length; j++) {
    var alreadyDownloaded = searchListForItem(savedBatches, 'batchId', batches[j].batchId);
    if (alreadyDownloaded === null) {
      // New items are manipulated here. An empty array of cask Ids is added for scanning.
      batches[j].casks = [];
      savedBatches.push(batches[j]);
    }
  }
  savedBatches.sort(sortByProperty('batchId'));

  // Write back to LS.
  setBatchesInStorage(savedBatches);
}

/**
 * @description Saves global variable of the scanning batch, including the batch ID and index from storage.
 * @param {String} batchId - The batch to be set for scanning.
 * @return {boolean} - Returns true if the scanning batch was set correctly.
 */
function setScanningFillingBatch(batchId) {
  "use strict";
  var batchesInStorage = getBatchesFromStorage();
  var requiredBatch = searchListForItem(batchesInStorage, 'batchId', batchId);
  if (requiredBatch !== null) {
    scanningFillingBatch.batchId = batchesInStorage[requiredBatch].batchId;
    scanningFillingBatch.indexInStorage = requiredBatch;
    return true;
  } else {
    showAlert("Error selecting the batch.");
    return false;
  }
}

/**
 * @description Find the current scanning filling batch in LS and return the full object.
 * @return {FillingBatch|null}
 */
function getScanningFillingBatchFromStorage() {
  "use strict";
  var batchesInStorage = getBatchesFromStorage();
  return batchesInStorage[scanningFillingBatch.indexInStorage] || null;
}

/**
 * @description Saves a caskID against the scanning batch, storing in LS.
 * @param caskObject
 */
function addCaskToBatch(caskObject) {
  "use strict";
  var batchesInStorage = getBatchesFromStorage();
  batchesInStorage[scanningFillingBatch.indexInStorage].casks.push(caskObject.caskId);
  setBatchesInStorage(batchesInStorage);
  return batchesInStorage[scanningFillingBatch.indexInStorage].casks;
}

/**
 * @description Checks all batches on the scanner to ensure the cask ID has never been scanned before.
 * Will show an alert if already scanned to indicate which batch it was on.
 * @param {Object} caskObject
 * @return {boolean} True if scanned before.
 */
function isAlreadyScannedInABatch(caskObject) {
  "use strict";
  var batchesInStorage = getBatchesFromStorage();

  for (var i=0; i<batchesInStorage.length; i++) {
    if (searchList(caskObject.caskId, batchesInStorage[i].casks)) {
      // The cask ID has been found on a batch. Show an error, immediately return.
      if (batchesInStorage[i].batchId === scanningFillingBatch.batchId) {
        showAlert("This has already been scanned against the batch.");
      } else {
        showAlert("This has already been scanned against another batch.");
      }
      return true;
    }
  }
  // Never found so we are safe to return false.
  return false;
}